.w3-theme-l5 {color:#000 !important; background-color:#fcf1f2 !important}
.w3-theme-l4 {color:#000 !important; background-color:#f5cfd2 !important}
.w3-theme-l3 {color:#000 !important; background-color:#eaa0a6 !important}
.w3-theme-l2 {color:#fff !important; background-color:#e07079 !important}
.w3-theme-l1 {color:#fff !important; background-color:#d5404d !important}
.w3-theme-d1 {color:#fff !important; background-color:#a2242e !important}
.w3-theme-d2 {color:#fff !important; background-color:#902029 !important}
.w3-theme-d3 {color:#fff !important; background-color:#7e1c24 !important}
.w3-theme-d4 {color:#fff !important; background-color:#6c181f !important}
.w3-theme-d5 {color:#fff !important; background-color:#5a141a !important}

.w3-theme-light {color:#000 !important; background-color:#fcf1f2 !important}
.w3-theme-dark {color:#fff !important; background-color:#5a141a !important}
.w3-theme-action {color:#fff !important; background-color:#5a141a !important}

.w3-theme {color:#fff !important; background-color:#b42733 !important}
.w3-text-theme {color:#b42733 !important}
.w3-border-theme {border-color:#b42733 !important}

.w3-hover-theme:hover {color:#fff !important; background-color:#b42733 !important}
.w3-hover-text-theme:hover {color:#b42733 !important}
.w3-hover-border-theme:hover {border-color:#b42733 !important}

.w3-hover-text-theme-active {color:#b42733 !important}

div.w3-container.scrollable {overflow-x: auto !important; overflow-y: auto;}
div.w3-container.scrollable::-webkit-scrollbar {background-color: transparent !important}
div.w3-container.scrollable::-webkit-scrollbar-thumb {background-color: transparent !important}

body::-webkit-scrollbar {width: 8px; background-color: transparent}
body::-webkit-scrollbar-track {background-color: transparent}
body::-webkit-scrollbar-thumb {background-color: lightgray; border-radius: 4px}
body::-webkit-scrollbar-thumb:hover {background-color: gray}

a.w3-bar-item.active {color:#b42733 !important}

.w3-button-loading {background-color: #ccc !important}

.toggle-icon {
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.logo {
	display: flex;
	position: fixed;
	bottom: 1rem;
	right: 6rem;
}

.logo .icon {
	background: var(--color-grey-normal);
	border-radius: 50%;
	padding: 15px;
	margin: 10px;
	width: 50px;
	height: 50px;
	font-size: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
	cursor: pointer;
	transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.logo .tooltip{
		position: absolute;
		top: 0;
		font-size: 14px;
		background: #ffffff;
		color: #ffffff;
		padding: 5px 8px;
		border-radius: 5px;
		box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
		opacity: 0;
		pointer-events: none;
		transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.logo .tooltip::before {
	position: absolute;
	content: "";
	height: 8px;
	width: 8px;
	background: #ffffff;
	bottom: -3px;
	left: 50%;
	transform: translate(-50%) rotate(45deg);
	transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  .logo .icon:hover .tooltip {
	top: -45px;
	opacity: 1;
	visibility: visible;
	pointer-events: auto;
  }
  
  .logo .icon:hover span,
  .logo .icon:hover .tooltip {
	text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
  }
  
  .logo .facebook:hover,
  .logo .facebook:hover .tooltip,
  .logo .facebook:hover .tooltip::before {
	background: #1877F2;
	color: #ffffff;
  }

  .logo .help:hover,
  .logo .help:hover .tooltip,
  .logo .help:hover .tooltip::before {
	background: lightgrey;
	color: #ffffff;
  }

  .logo .instagram:hover,
  .logo .instagram:hover .tooltip,
  .logo .instagram:hover .tooltip::before {
    background: #E4405F;
    color: #ffffff;
}

.logo .linkedin:hover,
.logo .linkedin:hover .tooltip,
.logo .linkedin:hover .tooltip::before {
  background: #0e76a8 ;
  color: #ffffff;
}

  .logo .icon span a {
	text-decoration: none;
	color: inherit;
}

.icon i {
	font-size: 18px;
} 

.flex-center {
	display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}