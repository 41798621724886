/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjxAwXjeu.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjx4wXg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Lato", sans-serif
}

/* styles.css */
.fill-style {
  fill: #da1d34;
  fill-opacity: 1;
  fill-rule: nonzero;
  stroke: none;
}

/* Style for the container of the toggle switch */
.toggle-container {
  display: inline-block;
  position: relative;
  width: 40px; /* Width of the toggle */
  height: 20px; /* Height of the toggle */
}

/* Hide the default checkbox */
.toggle-container input {
  opacity: 0;
  width: 40px; /* Width of the toggle */
  height: 20px; /* Height of the toggle */
}

/* The slider (the circle part of the toggle) */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc; /* Grey background when unchecked */
  transition: .4s; /* Transition effect */
  border-radius: 20px; /* Rounded edges */
}

/* The dot that slides when checked */
.slider:before {
  position: absolute;
  content: "";
  height: 16px; /* Height of the dot */
  width: 16px; /* Width of the dot */
  left: 2px; /* Positioning */
  bottom: 2px; /* Positioning */
  background-color: white; /* White dot */
  border-radius: 50%; /* Round dot */
  transition: .4s; /* Transition effect */
}

/* When the checkbox is checked */
input:checked + .slider {
  background-color: #b42733; /* Blue background when checked */
}

/* Move the dot to the right when checked */
input:checked + .slider:before {
  transform: translateX(20px); /* Moves the dot */
}

.input-with-unit {
  position: relative;
  display: flex;
  align-items: center;
}

.input-with-unit input {
  margin-right: 8px;
}

.unit-label {
  margin-left: 8px; /* Space between input and unit */
  font-size: 14px;
  color: #333;
}